import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'

const THEME = {
  dark: 'dark',
  light: 'light',
}

export const ThemeContext = React.createContext({
  theme: THEME.light,
  toggle: () => null,
})

export const useTheme = () => {
  const context = React.useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be within a ThemeProvider')
  }
  return context
}

export const ContextThemeProvider = ({ children }) => {
  // Set the default theme state to the value stored in the user's cookie and fallback
  // to 'light' if no cookie is found
  const [theme, setTheme] = useState(THEME.light)

  /**
   * Toggle between light and dark themes and set the current theme
   * value as a cookie. Also need to re-initialize the animate on scroll
   * module to ensure elements don't disappear.
   * @returns {void}
   */
  const toggleTheme = () => {
    const newThemeValue = theme === THEME.light ? THEME.dark : THEME.light
    Cookies.set('theme', newThemeValue)
    setTheme(newThemeValue)
  }

  useEffect(() => {
    if (Cookies.get('theme') !== theme) {
      setTheme(Cookies.get('theme'))
    }
  }, [theme])

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
