import React from 'react'
import { ThemeProvider } from 'styled-components'
import { ContextThemeProvider, useTheme } from './src/utils/context'
import './src/css/animate.css'

/**
 * Show outline only on keyboard interaction
 *
 * Adds 'js-focus-visible' class to body and 'focus-visible' class to focused element
 *
 * https://github.com/WICG/focus-visible
 * https://davidwalsh.name/css-focus
 */

const Wrapper = ({ children }) => {
  const { theme } = useTheme()
  return <ThemeProvider theme={{ color: theme }}>{children}</ThemeProvider>
}

export const wrapRootElement = ({ element }) => (
  <ContextThemeProvider>
    <Wrapper>{element}</Wrapper>
  </ContextThemeProvider>
)
